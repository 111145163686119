<template>
    <v-app-bar app flat style="background: #0033a0" dark>
      <v-spacer></v-spacer>
      <a href="http://www.blinn.edu">
      <v-img
        contain
        max-width="350"
        :src="require('../assets/logo-inverse-dev.svg')"
      /></a>
      <v-spacer></v-spacer>
    </v-app-bar>
</template>
<script>
export default {
    name:"Header"
}
</script>
<style scoped>

</style>