<template>
  <div>
    <v-footer style="background: #0033a0">
      <v-row justify="space-around" no-gutters>
        <v-btn text v-for="link in links" :key="link"
          ><a style="color: white" :href="link.url" target="_blank">{{
            link.name
          }}</a></v-btn
        >
      </v-row>
    </v-footer>
    <v-footer style="background: #0a2140">
      <v-row justify="space-around" no-gutters>
        <v-btn disabled text large v-for="number in numbers" :key="number"
          ><span style="color: white"
            >{{ number.label }}<br />{{ number.number }}</span
          ></v-btn
        >
        <div class="pa-1 d-flex flex-column align-center">
          <span class="font-weight-medium text-body-2 white--text"
            >RELLIS CAMPUS</span
          >
          <span>
            <a
              class="font-weight-medium text-body-2 white--text text-decoration-underline"
              href="mailto:RELLIS@blinn.edu"
              >RELLIS@blinn.edu</a
            >
          </span>
        </div>
      </v-row>
    </v-footer>
    <v-footer style="background: #0a2140">
      <v-row justify="center" no-gutters>
        <v-btn disabled text small
          ><span style="color: white"
            >&copy; 2021 Blinn College District</span
          ></v-btn
        >
      </v-row>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "WarningFooter",
  data() {
    return {
      links: [
        {
          name: "Privacy Notice",
          url: "http://www.blinn.edu/privacy-notice/index.html",
        },
        {
          name: "Open records",
          url:
            "http://www.blinn.edu/general-counsel/public-information-open-records.html",
        },
        {
          name: "Blinn Alert",
          url: "http://www.blinn.edu/alert/index.html",
        },
        {
          name: "Administrative Regulations",
          url: "http://www.blinn.edu/administrative-regulations/index.html",
        },
        {
          name: "Police",
          url: "http://www.blinn.edu/police-emergency-management/index.html",
        },
        {
          name: "Campus Maps",
          url: "http://www.blinn.edu/maps/index.html",
        },
      ],
      numbers: [
        {
          label: "Bryan Campus",
          number: "979-209-7200",
        },
        {
          label: "Brenham Campus",
          number: "979-830-4000",
        },
        {
          label: "Schulenburg Campus",
          number: "979-743-5200",
        },
        {
          label: "Sealy Campus",
          number: "979-627-7997",
        },
        {
          label: "Online Campus",
          number: "979-209-7298",
        },
      ],
    };
  },
};
</script>
<style>
a {
  text-decoration: none;
}
</style>