import Vue from "vue"
import VueRouter from "vue-router"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"

Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  {
    path: "/home",
    component: () => import("./components/Home.vue")
  },
  {
    path: "/",
    component: () => import("./components/ListResults.vue")
  },
  {
    path: "*",
    component: () => import("./components/FourOhFour.vue")
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL
})

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
