<template>
  <v-app>
    <Header />
    <v-main>
      <router-view></router-view>
    </v-main>
    <WarningFooter />
  </v-app>
</template>

<script>
import Header from "./components/Header"
import WarningFooter from "./components/WarningFooter"
export default {
  name: "App",
  components:{
    Header,
    WarningFooter
  }
};
</script>
<style scoped>
#warn {
  color: red;
}
</style>
